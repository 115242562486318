import * as MSAL from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./auth/authConfig";

export const dataProvider = async (route: string, method: "PUT" | "PATCH" | "POST" | "DELETE" | "GET" = "GET", body?: object) => {
	const app = new MSAL.PublicClientApplication(msalConfig);
	app.setActiveAccount(app.getAllAccounts()[0]);
	const token = await app.acquireTokenSilent({ scopes: loginRequest.scopes });
	const baseUrl = process.env.REACT_APP_API_URL!;

	let headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Authorization", "Bearer " + token.accessToken);

	let result = await fetch(baseUrl + route, {
		method: method,
		body: body ? JSON.stringify(body) : undefined,
		headers: headers,
	});

	if (!result.ok) {
		const errorResponse = await result.json();
		throw new Error(JSON.stringify({ status: result.status, errorMessage: errorResponse.errorMessage }));
	}

	return result;
};

export const s3Upload = async (route: string, csv: string) => {
	let headers = new Headers();
	headers.append("Content-Type", "text/csv");

	let result = await fetch(route, {
		method: "PUT",
		body: csv,
		headers: headers,
	});

	if (!result.ok) {
		const errorResponse = await result.json();
		throw new Error(JSON.stringify({ status: result.status, errorMessage: errorResponse.errorMessage }));
	}

	return result;
};
