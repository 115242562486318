import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createTheme" {
	interface Theme {
		borders?: any;
		customColors?: any;
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		borders?: any;
		customColors?: any;
	}
}

const palette = {
	primary: { main: "#082132" },
	secondary: { main: "#082132" },
};

const customColors = {
	gradient: {
		main: `linear-gradient(to left,  ${palette.primary.main}, ${palette.secondary.main})`,
	},
};

const borders = {
	light: { borderBottom: "0.1px solid #19233F" },
};

const typography = {
	fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
};

export default createTheme({
	palette,
	typography,
	borders,
	customColors,
});
