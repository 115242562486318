import { Model } from "./Model";
import { getModel } from "./functions/getModel";
import { createModel } from "./functions/createModel";
import { SourceProperties } from "./Source";
import { TagProperties } from "./Tag";

export interface RequestProperties {
	source?: SourceProperties[] | null;
	tags?: TagProperties[] | null;
	minAge?: number | null;
	maxAge?: number | null;
	locations?: string[] | null;
	s3FileName?: string | null;
	columnToMapIndex?: any;
}

interface RequestRelations {}

export class Request extends Model {
	public properties: RequestProperties;
	public relations: Partial<RequestRelations>;

	constructor() {
		super();

		this.properties = {} as any;
		this.relations = {};
	}

	override async save(query: string): Promise<void> {
		await createModel<Request>("Request", {
			...this.properties,
		}, query)
	}

	static async findById(id: number, altQuery?: string): Promise<Request> {
		return getModel<Request>("Request", [], id, altQuery);
	}
}
