import React, { useEffect, useState } from "react";
import { createStyles, ThemeProvider, withStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useAccount, useMsal } from "@azure/msal-react";

//SEN components
import theme from "../../theme";
import { AuthContext } from "../authProvider";
import { loginRequest } from "../authConfig";

const styles = () =>
	createStyles({
		root: {
			width: "100%",
			padding: "0px 40px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			borderRadius: "5px",
			color: "white",
			"@media (max-width: 780px)": {
				margin: "0px",
				padding: "0px 0px",
			},
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		textCenter: { textAlign: "center" },
		submitButton: {
			height: "60px",
			width: "100%",
			marginTop: "20px !important",
			border: "2px solid white !important",
			color: "white !important",
			"&:hover": {},
		},
		bgImage: {
			background: "linear-gradient(90deg, #082132, #00558f, #082132)",
			backgroundSize: "300% 300%",
			animation: "$gradient 5s alternate infinite",
		},
		"@keyframes gradient": {
			"0%": {
				backgroundPosition: "0%",
			},
			"100%": {
				backgroundPosition: "100%",
			},
		},
	});

interface LoginProps {
	classes: any;
}

interface LoginState {
	emailAddress: string;
	password: string;
	loading: boolean;
	error: string;
}

interface LoginButtonProps {
	loading: boolean;
	classes: any;
	context: any;
}

const LoginButton = (props: LoginButtonProps) => {
	const [loading, setLoading] = useState(false);
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});
	const { classes, context } = props;

	useEffect(() => {
		if (account && inProgress === "none") {
			setLoading(true);
			instance
				.acquireTokenSilent({
					scopes: loginRequest.scopes,
					account: account,
				})
				.then(async (res) => {
					context.setAuthenticated(true);
				})
				.catch((err) => {
					setLoading(false);
					context.setAuthenticated(false);
				});
		}
	}, [account, inProgress, instance, context]);

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		instance.loginRedirect(loginRequest).catch((e) => {
			context.setAuthenticated(false);
			console.log(e);
		});
	};

	return (
		<form onSubmit={submit} noValidate>
			<h4>Login with your SEN account</h4>
			<Button type="submit" size="large" className={[classes.w100, classes.submitButton].join(" ")}>
				{!loading ? <>Login With SSO</> : <CircularProgress size={30} />}
			</Button>
		</form>
	);
};

class Login extends React.Component<LoginProps, LoginState> {
	static contextType = AuthContext;

	constructor(props: LoginProps) {
		super(props);
		this.state = {
			emailAddress: "",
			password: "",
			error: "",
			loading: false,
		};
	}

	render() {
		const { classes } = this.props;

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />

				<div className={classes.bgImage}>
					<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
						<Grid item xs={3}>
							<div className={classes.root}>
								<div className={[classes.w100, classes.marginBottom20].join(" ")}>
									<LoginButton loading={this.state.loading} classes={classes} context={this.context} />
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles)(Login);
