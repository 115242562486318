import React, { useState, useEffect } from "react";
import { createStyles, withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";

//SEN components
import theme from "../theme";
import { Request } from "../models/Request";

const styles = () =>
	createStyles({
		root: {
			background: "linear-gradient(90deg, #082132, #00558f, #082132)",
			backgroundSize: "300% 300%",
			animation: "$gradient 5s alternate infinite",
			paddingTop: "10px",
			height: "calc(100vh - 60px)",
		},
		"@keyframes gradient": {
			"0%": {
				backgroundPosition: "0%",
			},
			"100%": {
				backgroundPosition: "100%",
			},
		},
		backdrop: {
			background: "transparent",
		},
		bottomDiv: {
			position: "absolute",
			width: "200px",
			height: "30px",
			bottom: "0px",
			left: "50%",
			marginLeft: "-100px",
		},
		link: {
			textDecoration: "none",
			marginRight: "7px",
			color: theme.palette.primary.main,
		},
	});

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

interface DownloadProps {
	classes?: any;
	match: any;
}

const Download = (props: DownloadProps) => {
	const [stage, setStage] = useState(0);
	const [progress, setProgress] = useState(100);
	const [error, setError] = useState("");
	const { classes } = props;

	// Get extract
	const extractId = props.match.params.extractId;

	useEffect(() => {
		async function startDownload() {
			//Fetch here
			try {
				const response = (await Request.findById(extractId, "requests/extracts")) as any;
				setStage(1);

				var dload = document.createElement("a");
				dload.setAttribute("target", "_blank");
				dload.setAttribute("href", response.properties.signedUrl);
				dload.setAttribute("download", response.properties.fileName);
				setStage(2);

				if (document.createEvent) {
					var event = document.createEvent("MouseEvents");
					event.initEvent("click", true, true);
					dload.dispatchEvent(event);
					setProgress(100);
				} else {
					dload.click();
				}
			} catch (err: any) {
				const errorObj = JSON.parse(err.message);
				setError(errorObj.errorMessage);
			}
		}

		setTimeout(() => startDownload(), 1000);
	}, [extractId]);

	return (
		<div className={classes.root}>
			<Container maxWidth="md">
				<Grid container style={{ background: "transparent" }}>
					<Grid item xs={12} style={{ padding: "15px 0px", boxShadow: "none !important", background: "white", borderRadius: "5px" }}>
						<h1 style={{ margin: 0, paddingLeft: "15px", fontSize: "30px" }}>Downloading Data</h1>
						<Item elevation={0}>
							{error.length !== 0 ? (
								<Alert severity="error" style={{ paddingLeft: "15px", marginBottom: "20px", color: "black" }}>
									{error}
								</Alert>
							) : (
								<>
									{stage === 0 && (
										<>
											<h4 style={{ marginBottom: "20px", color: "black" }}>Downloading Extract</h4>
											<CircularProgress />
										</>
									)}
									{stage === 1 && (
										<>
											<h4 style={{ marginBottom: "20px", color: "black" }}>Downloading Extract</h4>
											<CircularProgress variant="determinate" value={progress} />
										</>
									)}
									{stage === 2 && (
										<>
											<h4 style={{ marginBottom: "20px", color: "black" }}>Saving File</h4>
											<DoneIcon sx={{ fontSize: "40px", color: "#378805" }} />
										</>
									)}
								</>
							)}
						</Item>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default withStyles(styles)(Download);
