export const querifyObject = (obj: object) => {
  let query = "?";

  for (const property in obj) {
    query += `${property}=${obj[property as keyof typeof obj]}`;
    query += "&";
  }

  if (query.endsWith("&")) {
    query = query.slice(0, -1);
  }

  return query;
};
