export abstract class Model {
	public id?: number;
	public properties: object;
	public relations: object;

	constructor() {
		this.properties = {};
		this.relations = {};
	}

	abstract save(query?: string): Promise<void>;
}
