import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles, createStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { useMsal } from "@azure/msal-react";

//SEN components
import theme from "../theme";
import { AuthContext } from "../auth/authProvider/AuthContext";

interface Props {
	window?: () => Window;
	children?: React.ReactElement;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			position: "fixed",
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
		marginLeft10: {
			marginLeft: "10px",
		},
		navLogo: {
			height: "25px",
			marginTop: "5px",
		},
		textCenter: {
			textAlign: "center",
		},
		textRight: {
			textAlign: "right",
		},
		fullWidth: {
			width: "100%",
		},

		secondaryBack: {
			background: "linear-gradient(90deg, #082132, #00558f, #082132)",
			backgroundSize: "300% 300%",
			animation: "$gradient 5s alternate infinite",
		},
		"@keyframes gradient": {
			"0%": {
				backgroundPosition: "0%",
			},
			"100%": {
				backgroundPosition: "100%",
			},
		},
		pointer: {
			cursor: "pointer",
		},
		whiteButton: {
			background: "transparent",
			color: "white",
			border: "1px solid white",
			padding: "5px 35px",
			borderRadius: "3px",
		},
	})
);

export default function Nav(props: Props) {
	const classes = useStyles();
	const { authenticated, setAuthenticated } = React.useContext(AuthContext);
	//const isAdmin = ["readOnlyAdmin", "fullAccessAdmin"].includes(String(localStorage.getItem("role")));
	const { instance } = useMsal();

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar className={[classes.secondaryBack].join(" ")} elevation={0}>
				<Toolbar>
					<div className={[classes.fullWidth].join(" ")}>
						<Link href={"/"}></Link>
					</div>
					{authenticated && (
						<div
							className={[classes.marginLeft10, classes.textRight, classes.pointer, classes.whiteButton].join(" ")}
							onClick={() => {
								instance.logoutRedirect({ postLogoutRedirectUri: "/" });
								setAuthenticated(false);
							}}
						>
							Logout
						</div>
					)}
				</Toolbar>
			</AppBar>
			<Toolbar id="back-to-top-anchor" />
		</React.Fragment>
	);
}
