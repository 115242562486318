import { dataProvider } from "../../dataProvider";
import { Model } from "../Model";

export const createModel = async <T extends Model>(type: "Request" | "Tag" | "Source", data: object, altQuery?: string) => {
	const Models = (await import("../Models")).default;

	try {
		let queryString = altQuery ? altQuery : type.toLowerCase();
		let result = await dataProvider(queryString, "POST", data);
		let json = await result.json();
		let model = new Models[type]();
		model.properties = json;
		model.id = json.id;

		return model as T;
	} catch (error) {
		alert(error);
		throw error;
	}
};
