import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//Auth
import Login from "../subComponents/Login";
import Home from "../../dashboard/Home";
import NavBar from "../../dashboard/NavBar";
import Download from "../../dashboard/Download";

import { AuthContext } from "./AuthContext";

interface AuthRouteProps {
	component?: any;
}

interface AuthRouteState {}

class AuthRoute extends React.Component<AuthRouteProps, AuthRouteState> {
	static contextType = AuthContext;

	render() {
		//const { authenticated, role } = this.context;
		const { authenticated } = this.context;
		return (
			<BrowserRouter>
				{authenticated ? (
					<>
						<NavBar />
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/extracts/:extractId" component={Download} />
							<Redirect to="/" />
						</Switch>
					</>
				) : (
					<BrowserRouter>
						<Switch>
							<Route exact path="/" component={Login} />
							<Redirect to="/" />
						</Switch>
					</BrowserRouter>
				)}
			</BrowserRouter>
		);
	}
}

export default AuthRoute;
