import React, { useState } from "react";
import Button from "@mui/material/Button";
import { CSVReader } from "react-papaparse";
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../theme";
import UploadModal from "./UploadModal";
import { dataProvider, s3Upload } from "../dataProvider";

const dragDropStyle = {
	dropArea: {
		border: "3px dashed " + theme.palette.primary.main,
		borderRadius: 3,
		padding: "50px 0px",
		margin: "5px",
		elevation: 0,
	},
	dropAreaActive: {
		borderColor: "#00B6FF",
	},
	dropFile: {
		background: "transparent",
		width: "50px",
		height: "23px",
	},
	fileSizeInfo: {
		color: "black",
		backgroundColor: "white",
		lineHeight: 1,
		marginBottom: "0.5em",
		padding: "0 0.4em",
		display: "none",
	},
	fileNameInfo: {
		color: "black",
		backgroundColor: "white",
		borderRadius: 3,
		fontSize: 14,
		lineHeight: 1,
		display: "none",
	},
	removeButton: {
		color: "#00B6FF",
		display: "none",
	},
	progressBar: {
		backgroundColor: "#00B6FF",
		display: "none",
	},
};

interface UploadFileProps {
	sources: any;
	tags: any;
	locations: any;
}

async function getSignedUrl(file: any) {
	const r = await dataProvider("s3", "PUT", {
		objectName: file.name,
		contentType: file.type,
	});
	return await r.json();
}

export default function UploadFile(props: UploadFileProps) {
	//const isAdmin = ["readOnlyAdmin", "fullAccessAdmin"].includes(String(localStorage.getItem("role")));
	const [showDataMappingModal, setDataMappingModal] = useState(false);
	const [csvData, setCsvData] = useState([] as any[]);
	const [uploading, setUploading] = useState(false);
	const [s3FileName, setS3FileName] = useState("");

	const handleOnDrop = async (data: any) => {
		setUploading(true);
		const s3File = `upload-${Date.now()}.csv`;
		let signedUrl;
		try {
			signedUrl = await getSignedUrl({ name: s3File, type: "text/csv" });
			console.log(signedUrl);
		} catch (error) {
			setUploading(false);
			return;
		}

		let dataToCSV = data
			.map((item: any) => {
				return item.data.join(",");
			})
			.join("\n");

		setS3FileName(s3File);

		try {
			await s3Upload(signedUrl.signedUrl, dataToCSV);
			setDataMappingModal(true);
			setCsvData(data);
			setTimeout(() => {
				setUploading(false);
			}, 500);
		} catch (error: any) {
			console.log(error);
			setUploading(false);
		}
	};

	const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
		console.log(err);
	};

	const handleOnRemoveFile = (data: any) => {
		setTimeout(() => {
			setCsvData([]);
		}, 1500);
	};

	return (
		<div style={{ minHeight: "196px", boxShadow: "none !important" }}>
			{uploading === false ? (
				<CSVReader onDrop={handleOnDrop} onError={handleOnError} addRemoveButton style={dragDropStyle} onRemoveFile={handleOnRemoveFile}>
					<label htmlFor="icon-button-file">
						<input style={{ display: "none" }} accept=".csv" id="icon-button-file" type="file" />
						<h3 style={{ marginTop: 0 }}>Drop File Here Or</h3>

						<Button
							color="inherit"
							aria-label="upload"
							style={{
								background: "#1976d2",
								color: "white",
								width: "140px",
								boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgb(0, 0, 0, 0.12)",
							}}
						>
							Upload
						</Button>
					</label>
				</CSVReader>
			) : (
				<div style={{ padding: "67px", margin: "5px", border: "3px dashed " + theme.palette.primary.main, borderRadius: 3 }}>
					<CircularProgress size="50px" />
				</div>
			)}

			<UploadModal s3FileName={s3FileName} csvData={csvData} setCsvData={setCsvData} showDataMappingModal={showDataMappingModal} setDataMappingModal={setDataMappingModal} {...props} />
		</div>
	);
}
