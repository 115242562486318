import React from "react";
import { createStyles, withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

//SEN components
import theme from "../theme";
import Guide from "./Guide";
import UploadFileHome from "./UploadFileHome";
import DatabaseQueryCreator from "./DatabaseQueryCreator";

import { Source } from "../models/Source";
import { Tag } from "../models/Tag";

const styles = () =>
	createStyles({
		root: {
			background: "linear-gradient(90deg, #082132, #00558f, #082132)",
			backgroundSize: "300% 300%",
			animation: "$gradient 5s alternate infinite",
			paddingTop: "10px",
			minHeight: "calc(100vh - 60px)",
		},
		"@keyframes gradient": {
			"0%": {
				backgroundPosition: "0%",
			},
			"100%": {
				backgroundPosition: "100%",
			},
		},
		backdrop: {
			background: "transparent",
		},
		bottomDiv: {
			position: "absolute",
			width: "200px",
			height: "30px",
			bottom: "0px",
			left: "50%",
			marginLeft: "-100px",
		},
		link: {
			textDecoration: "none",
			marginRight: "7px",
			color: theme.palette.primary.main,
		},
	});

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

interface HomeProps {
	classes?: any;
}

interface HomeState {
	sources: Source[];
	tags: Tag[];
}

class Home extends React.Component<HomeProps, HomeState> {
	constructor(props: HomeProps) {
		super(props);
		this.state = { sources: [], tags: [] };
	}

	async componentDidMount() {
		const sources = await Source.findMany();
		const tags = await Tag.findMany();
		this.setState({ sources, tags });
	}

	render() {
		const { classes } = this.props;
		const locations = ["ALL", "VIC", "NSW", "QLD", "SA", "TAS", "WA", "NT", "ACT","NZ", "Other"];

		return (
			<div className={classes.root}>
				<Container maxWidth="md">
					<Grid container style={{ background: "transparent" }}>
						<Grid item xs={12} style={{ padding: "15px 0px", boxShadow: "none !important", background: "white", borderRadius: "5px" }}>
							<h1 style={{ margin: 0, paddingLeft: "15px", fontSize: "30px" }}>Import Data</h1>
							<Item elevation={0}>
								<UploadFileHome locations={locations} sources={this.state.sources} tags={this.state.tags} />
							</Item>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								boxShadow: "none !important",
								height: "30px",
								background: "transparent",
							}}
						></Grid>
						<Grid item xs={12} style={{ padding: "15px 0px", boxShadow: "none !important" }}>
							<Item>
								<DatabaseQueryCreator sources={this.state.sources} tags={this.state.tags} locations={locations} />
							</Item>
						</Grid>
					</Grid>
				</Container>
				<Guide />
			</div>
		);
	}
}

export default withStyles(styles)(Home);
