import { SearchParams } from "../../types/SearchParams";
import { dataProvider } from "../../dataProvider";
import { Model } from "../Model";
import { querifyObject } from "./querifyObject";

export const listModel = async <T extends Model, R>(type: "Request" | "Source" | "Tag", relationKeys: string[], searchParams: SearchParams<R>, altQuery?: string) => {
	const Models = (await import("../Models")).default;

	if (!searchParams.sortBy) {
		searchParams.sortBy = "id";
	}

	searchParams = JSON.parse(JSON.stringify(searchParams));

	let query = type.toLowerCase() + querifyObject(searchParams);
	if (altQuery) query = altQuery.toLowerCase() + querifyObject(searchParams);

	let result = await dataProvider(query, "GET");
	let json = await result.json();

	let models = [];

	for (const properties of json) {
		let model = new Models[type]();
		model.properties = properties;
		model.id = properties.id;
		for (const key in properties) {
			if (relationKeys.includes(key)) {
				(model.relations as any)[key] = properties[key];
			}
		}
		models.push(model);
	}

	return models as T[];
};
