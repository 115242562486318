import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props: any) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const anchorButtonStyle = {};

interface GuideProps {}

interface GuideState {}

export default function Guide(props: GuideProps, state: GuideState) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Fab
				sx={{
					position: "absolute",
					bottom: 25,
					right: 25,
					color: "common.white",
					bgcolor: "#04A7FF",
					"&:hover": {
						bgcolor: "#034877",
					},
					fontSize: "20px",
					fontWeight: "bold",
				}}
				color="inherit"
				onClick={handleClickOpen}
				style={anchorButtonStyle}
			>
				?
			</Fab>
			<BootstrapDialog onClose={handleClose} open={open}>
				<DialogContent dividers>
					<Typography gutterBottom>Drag & drop your files onto one of the the upload buttons or simply click the icon to upload.</Typography>
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
}
