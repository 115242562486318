import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBackIcon from "@mui/icons-material/NavigateBefore";
import CheckIcon from "@mui/icons-material/Check";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";

import { Request } from "../models/Request";

const filterSources = createFilterOptions();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

interface UploadModalProps {
	csvData: any;
	setCsvData: (data: any) => void;
	showDataMappingModal: boolean;
	setDataMappingModal: (data: boolean) => void;
	sources: any;
	tags: any;
	s3FileName: string;
	locations: any;
}

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value + 1); // update the state to force render
}

export default function UploadModal(props: UploadModalProps) {
	const { csvData, setCsvData, showDataMappingModal, setDataMappingModal } = props;

	const [mapping, setMapping] = useState([{ dbValue: null, mappedValue: null }] as any[]);
	const [disableButton, setDisableButton] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [source, setSource] = useState(null);
	const [tags, setTags] = useState([]);
	const [success, setSuccess] = useState(false);
	const [postingData, setPostingData] = useState(false);
	const [includesEmail, setIncludesEmail] = useState(false);

	const forceUpdate = useForceUpdate();
	const columns = ["First Name", "Last Name", "Full Name", "Date of Birth", "Gender", "Email", "Phone", "State", "Postcode"];

	const updateMappingByIndex = (index: number, key: string, value: string) => {
		let newMapping = mapping as any;
		newMapping[index][key] = value;
		setMapping(newMapping);

		if (newMapping[index].dbValue && newMapping[index].mappedValue && getRemainingColumnOptions(index).length > 0) {
			setDisableButton(false);
		}

		for (const newMap of newMapping) {
			if (newMap.dbValue && newMap.mappedValue) {
				if (newMap.dbValue.includes("Email")) {
					if (newMap.dbValue && newMap.mappedValue) {
						setIncludesEmail(true);
					}
				}
			}
		}

		forceUpdate();
	};

	const getRemainingColumnOptions = (index: number) => {
		return columns.filter((item, ind) => {
			for (const mappingItem of mapping) {
				if (mappingItem.dbValue === item) {
					return false;
				}
			}
			return true;
		});
	};

	const deleteMapping = (index: number) => {
		let newMapping = mapping as any;
		newMapping.splice(index, 1);
		setMapping(newMapping);

		if (newMapping.length === 0) {
			setDisableButton(false);
		}

		let newMappingIncludesEmail = false;
		for (const newMap of newMapping) {
			if (newMap.dbValue.includes("Email")) {
				newMappingIncludesEmail = true;
			}
		}
		setIncludesEmail(newMappingIncludesEmail);

		forceUpdate();
	};

	const addMapping = () => {
		setDisableButton(true);
		let newMapping = mapping as any;
		newMapping.push({ dbValue: null, mappedValue: null });
		setMapping(newMapping);
	};

	const pageUp = () => {
		setPageNumber(pageNumber + 1);
	};

	const pageDown = () => {
		setPageNumber(pageNumber - 1);
	};

	const postData = async () => {
		setPostingData(true);
		let columnToMapIndex: any = {};

		//Get column to map index data
		for (const map of mapping) {
			for (let colIndex = 0; colIndex < csvData[0].data.length; colIndex++) {
				if (csvData[0].data[colIndex].trim() === map.mappedValue.trim()) {
					columnToMapIndex[map.dbValue] = csvData[0].data[colIndex];
				}
			}
		}

		//create object of all info needed;
		try {
			console.log();
			let request = new Request();
			request.properties.s3FileName = props.s3FileName;
			request.properties.source = (source! as any).properties;
			request.properties.tags = (tags! as any).map((x: any) => x.properties);
			request.properties.columnToMapIndex = columnToMapIndex;
			console.log(request.properties);
			await request.save("requests/csvUpload");

			setTimeout(() => {
				setSuccess(true);
				setDisableButton(false);
				setMapping([{ dbValue: null, mappedValue: null }]);
				setCsvData([]);
				setTags([]);
				setSource(null);
				setPostingData(false);
				setDataMappingModal(false);
				setPageNumber(0);
				setIncludesEmail(false);
			}, 2000);
		} catch (err) {
			setPostingData(false);
			setDisableButton(false);
		}
	};

	const handleCloseMappingModal = () => {
		setDataMappingModal(false);
		setTimeout(() => {
			setDisableButton(false);
			setMapping([{ dbValue: null, mappedValue: null }]);
			setCsvData([]);
			setTags([]);
			setSource(null);
			setPostingData(false);
			setPageNumber(0);
			setIncludesEmail(false);
		}, 1000);
	};

	return (
		<>
			<BootstrapDialog onClose={handleCloseMappingModal} open={showDataMappingModal}>
				<DialogContent dividers style={{ height: "500px" }}>
					{pageNumber === 0 && (
						<>
							<h3 style={{ marginBottom: "0px" }}>Map Your Data</h3>
							<p style={{ marginTop: 0, marginBottom: "15px", fontSize: "12px" }}>
								<i>NOTE: Your data must include the email field</i>
							</p>
							<div style={{ width: "100%" }}>
								<div style={{ width: "500px", margin: "auto", textAlign: "left" }}>
									<div>
										<>
											<div style={{ width: "100%", fontSize: "12px", color: "grey" }}>
												<div style={{ width: "40%", float: "left", overflow: "visible", marginRight: "10px" }}>CSV Field</div>
												<div style={{ width: "40%", float: "left" }}>Field</div>
											</div>
											{mapping.map((item, index) => {
												return (
													<div style={{ width: "100%", marginTop: "10px", overflow: "hidden" }}>
														<div style={{ width: "40%", float: "left", overflow: "visible", marginRight: "10px" }}>
															<Autocomplete
																disablePortal
																options={csvData.length ? csvData[0].data : []}
																value={mapping[index].mappedValue}
																onChange={(e: any) => updateMappingByIndex(index, "mappedValue", e.target.innerText || "")}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		InputLabelProps={{
																			style: { color: "white", fill: "white" },
																		}}
																		label="Select"
																	/>
																)}
															/>
														</div>
														<div style={{ width: "40%", float: "left" }}>
															<Autocomplete
																disablePortal
																options={getRemainingColumnOptions(index)}
																value={mapping[index].dbValue}
																onChange={(e: any) => updateMappingByIndex(index, "dbValue", e.target.innerText || "")}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		InputLabelProps={{
																			style: { color: "white", fill: "white" },
																		}}
																		label="Select"
																	/>
																)}
															/>
														</div>

														<div style={{ width: "10%", float: "left", marginTop: "10px" }}>
															<IconButton onClick={() => deleteMapping(index)} aria-label="remove mapping" style={{ marginLeft: "20px" }}>
																<DeleteIcon />
															</IconButton>
														</div>
													</div>
												);
											})}
										</>
									</div>
								</div>
								<div style={{ width: "500px", marginTop: "20px", textAlign: "center" }}>
									<IconButton
										onClick={addMapping}
										disabled={disableButton}
										aria-label="add mapping"
										color="inherit"
										style={disableButton === false ? { background: "#063B60" } : { background: "#063B60", opacity: 0.7 }}
									>
										<AddIcon style={{ color: "white" }} />
									</IconButton>
									<IconButton
										disabled={!(mapping.length > 0 && mapping[0].dbValue && mapping[0].mappedValue && includesEmail)}
										onClick={pageUp}
										aria-label="next"
										color="inherit"
										style={
											!(mapping.length > 0 && mapping[0].dbValue && mapping[0].mappedValue && includesEmail)
												? { background: "#063B60", marginLeft: "10px", opacity: 0.7 }
												: { background: "#063B60", marginLeft: "10px" }
										}
									>
										<CheckIcon style={{ color: "white" }} />
									</IconButton>
								</div>
							</div>
						</>
					)}
					{pageNumber === 1 && (
						<>
							<h3 style={{ marginBottom: "5px" }}>Classify Your Data</h3>
							<div style={{ width: "100%" }}>
								<div style={{ width: "500px", margin: "auto", textAlign: "left", overflow: "hidden" }}>
									<p style={{ fontSize: "12px", color: "grey", margin: 0 }}>Source</p>
									<Autocomplete
										value={source}
										onChange={(event: any, newValue: any) => {
											if (typeof newValue === "string") {
												setSource(newValue as any);
											} else if (newValue && newValue.inputValue) {
												// Create a new value from the user input
												setSource(newValue.inputValue as any);
											} else {
												setSource(newValue as any);
											}
										}}
										filterOptions={(options: any, params: any) => {
											const filtered = filterSources(options, params);
											return filtered;
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										options={props.sources.filter((x: any) => x.id !== "ALL")}
										getOptionLabel={(option: any) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}

											// Regular option
											return option.properties.name;
										}}
										renderOption={(props: any, option: any) => <li {...props}>{option.properties.name}</li>}
										sx={{ width: 500, margin: "10px 0px 20px 0px" }}
										freeSolo
										renderInput={(params) => <TextField {...params} label="Start typing a source..." />}
									/>
								</div>
								<div style={{ width: "500px", margin: "auto", textAlign: "left", overflow: "hidden" }}>
									<p style={{ fontSize: "12px", color: "grey", margin: 0 }}>Tags</p>
									<Autocomplete
										multiple
										options={props.tags.filter((x: any) => x.id !== "ALL")}
										sx={{ width: 500, margin: "10px 0px 20px 0px" }}
										getOptionLabel={(option: any) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.inputValue;
											}
											// Regular option
											return option.properties.name;
										}}
										onChange={(event: any, newValue: any) => {
											setTags(newValue);
										}}
										defaultValue={tags}
										filterSelectedOptions
										filterOptions={(options: any, params: any) => {
											const filtered = filterSources(options, params);
											return filtered;
										}}
										freeSolo
										renderOption={(props: any, option: any) => <li {...props}>{option.properties.name}</li>}
										renderInput={(params: any) => <TextField {...params} label="Tag your data" placeholder="Tag your data" />}
									/>
								</div>
								<div style={{ width: "500px", marginTop: "20px", textAlign: "center" }}>
									<IconButton onClick={pageDown} aria-label="next" color="inherit" style={{ background: "#063B60", marginLeft: "10px" }}>
										<NavigateBackIcon style={{ color: "white" }} />
									</IconButton>
									<IconButton onClick={pageUp} aria-label="next" color="inherit" style={{ background: "#063B60", marginLeft: "10px" }}>
										<NavigateNextIcon style={{ color: "white" }} />
									</IconButton>
								</div>
							</div>
						</>
					)}
					{pageNumber === 2 && (
						<>
							{!success && (
								<div style={{ width: "500px", marginTop: "20px", textAlign: "center" }}>
									<h3>Submit Data</h3>

									<IconButton onClick={pageDown} aria-label="next" color="inherit" style={{ background: "#063B60", marginLeft: "10px" }}>
										<NavigateBackIcon style={{ color: "white" }} />
									</IconButton>
									<IconButton onClick={postData} aria-label="tick" color="inherit" style={{ background: "#063B60", marginLeft: "10px" }}>
										{!postingData ? <CheckIcon style={{ color: "white" }} /> : <CircularProgress style={{ color: "white" }} size={25} />}
									</IconButton>
								</div>
							)}
						</>
					)}
				</DialogContent>
			</BootstrapDialog>
			<Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
				<Alert onClose={() => setSuccess(false)} severity="success">
					Success! Larger files (20,000+ rows) may take a few minutes to fully upload.
				</Alert>
			</Snackbar>
		</>
	);
}
