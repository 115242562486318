import { Model } from "./Model";
import { SearchParams } from "../types/SearchParams";
import { listModel } from "./functions/listModel";
import { createModel } from "./functions/createModel";

export interface SourceProperties {
	id: string;
	name: string;
}

interface SourceRelations {}

export class Source extends Model {
	public properties: SourceProperties;
	public relations: Partial<SourceRelations>;

	constructor() {
		super();

		this.properties = {} as any;
		this.relations = {};
	}

	static async findMany(searchParams?: SearchParams<SourceProperties>): Promise<Source[]> {
		return listModel<Source, SourceProperties>("Source", [], searchParams ?? {}, "sources/all");
	}
	
	override async save(): Promise<void> {
		this.id = (
			await createModel<Source>("Source", {
				...this.properties,
			})
		).id;
	}
}
