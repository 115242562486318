import React from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import { AuthProvider } from "./components/auth/authProvider";
import AuthRoute from "./components/auth/authProvider/AuthRoute";

function App() {
	let history = useHistory();

	const handleLogin = () => {
		if (window.location.pathname === "/") {
			history.push("/");
		}
		localStorage.setItem("expiry", JSON.stringify(Math.floor(Date.now() / 1000) + 60 * 60 * 24));
	};

	const handleLogout = () => {
		history.push("/");
	};

	const checkForExistingToken = () => {
		const expiry = parseInt(localStorage.getItem("expiry") ?? "0");
		if (expiry) {
			const today = new Date();
			return Math.floor(today.getTime() / 1000) < expiry;
		}
		return false;
	};

	return (
		<AuthProvider onLogin={handleLogin} onLogout={handleLogout} defaultAuthenticated={checkForExistingToken()}>
			<AuthRoute />
		</AuthProvider>
	);
}

export default App;
