import { Model } from "./Model";
import { SearchParams } from "../types/SearchParams";
import { listModel } from "./functions/listModel";
import { createModel } from "./functions/createModel";

export interface TagProperties {
	id: string;
	name: string;
}

interface TagRelations {
}

export class Tag extends Model {
	public properties: TagProperties;
	public relations: Partial<TagRelations>;

	constructor() {
		super();

		this.properties = {} as any;
		this.relations = {};
	}

	static async findMany(searchParams?: SearchParams<TagProperties>): Promise<Tag[]> {
		return listModel<Tag, TagProperties>("Tag", [], searchParams ?? {}, "tags/all");
	}
	
	override async save(): Promise<void> {
		this.id = (
			await createModel<Tag>("Tag", {
				...this.properties,
			})
		).id;
	}
}
