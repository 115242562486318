import React, { useState, useEffect } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TodayIcon from "@mui/icons-material/Today";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import { Request } from "../models/Request";
import { Source, SourceProperties } from "../models/Source";
import { Tag, TagProperties } from "../models/Tag";

interface DatabaseQueryCreatorProps {
	sources: Source[];
	tags: Tag[];
	locations: any;
}

const filterSources = createFilterOptions();

export default function DatabaseQueryCreator(props: DatabaseQueryCreatorProps) {
	const [source, setSource] = useState([]);
	const [tags, setTags] = useState([]);
	const [locations, setLocations] = useState([]);

	const [minAge, setMinAge] = useState(null);
	const [maxAge, setMaxAge] = useState(null);
	const [disableButton, setDisableButton] = useState(true);
	const [postingData, setPostingData] = useState(false);
	const [errorState, setErrorState] = useState(false);
	const [successState, setSuccessState] = useState(false);

	useEffect(() => {
		if (source.length || tags.length) {
			setDisableButton(false);
			return;
		}
		setDisableButton(true);
	}, [source, tags]);

	async function requestData() {
		setDisableButton(true);
		setPostingData(true);
		setErrorState(false);
		setSuccessState(false);

		try {
			const request = new Request();
			request.properties = {
				source: source.map((x: Source) => {
					console.log(x.properties);
					return x.properties;
				}) as SourceProperties[],
				tags: tags.map((x: Tag) => {
					console.log(x.properties);
					return x.properties;
				}) as TagProperties[],
				locations,
				minAge,
				maxAge,
			};
			await request.save("requests/csv");

			setSuccessState(true);
			setDisableButton(false);
			setPostingData(false);
		} catch (err) {
			console.log(err);
			setErrorState(true);
			setDisableButton(false);
			setPostingData(false);
		}
	}

	return (
		<div style={{ overflow: "hidden", textAlign: "left", paddingLeft: "15px", paddingRight: "15px" }}>
			{errorState && (
				<Alert onClose={() => setErrorState(false)} severity="error">
					An unknown error occured. Please try again or contact internalapps@sen.com.au
				</Alert>
			)}
			{successState && (
				<Alert onClose={() => setSuccessState(false)} severity="success">
					Your request is being processed. You will receive an email when it is ready.
				</Alert>
			)}
			<div>
				<h1 style={{ color: "black", marginBottom: "10px", marginTop: "10px", fontSize: "30px" }}>Export Data</h1>
			</div>
			<Grid container style={{ background: "white", borderRadius: "5px" }}>
				<Grid item md={6} xs={6} style={{ width: "100%", paddingRight: "20px" }}>
					<div style={{ width: "100%", overflow: "hidden" }}>
						<AppsIcon style={{ float: "left" }} />
						<h4 style={{ float: "left", margin: 0, lineHeight: "27px", marginLeft: "10px" }}>Source</h4>
					</div>
					<Autocomplete
						multiple
						options={props.sources}
						sx={{ width: "100%", margin: "10px 0px 20px 0px" }}
						getOptionLabel={(option: any) => {
							// Value selected with enter, right from the input
							if (typeof option === "string") {
								return option;
							}

							// Regular option
							return option.properties.name;
						}}
						onChange={(event: any, newValue: any) => {
							setSource(newValue);
						}}
						filterSelectedOptions
						filterOptions={(options: any, params: any) => {
							const filtered = filterSources(options, params);
							const hasAll =
								source
									.map(function (e: any) {
										return e.id;
									})
									.indexOf("ALL") !== -1
									? true
									: false;
							return !hasAll ? filtered : [];
						}}
						freeSolo
						renderOption={(props: any, option: any) => (
							<li style={option.id === "ALL" ? { fontWeight: "bold", color: "#034C7F", fontStyle: "italic" } : {}} {...props}>
								{option.properties.name}
							</li>
						)}
						renderInput={(params: any) => <TextField {...params} placeholder="Source" />}
						ListboxProps={{
							style: {
								maxHeight: "150px",
							},
						}}
					/>
				</Grid>
				<Grid item md={6} xs={6} style={{ width: "100%" }}>
					<div style={{ width: "100%", overflow: "hidden" }}>
						<LocationOnIcon style={{ float: "left" }} />
						<h4 style={{ float: "left", margin: 0, lineHeight: "27px", marginLeft: "10px" }}>Location</h4>
					</div>
					<Autocomplete
						multiple
						options={props.locations}
						sx={{ width: "100%", margin: "10px 0px 20px 0px" }}
						getOptionLabel={(option: any) => {
							// Value selected with enter, right from the input
							if (typeof option === "string") {
								return option;
							}

							// Regular option
							return option;
						}}
						onChange={(event: any, newValue: any) => {
							setLocations(newValue);
						}}
						filterSelectedOptions
						filterOptions={(options: any, params: any) => {
							const filtered = filterSources(options, params);
							const hasAll =
								locations
									.map(function (e: any) {
										return e;
									})
									.indexOf("ALL") !== -1
									? true
									: false;
							return !hasAll ? filtered : [];
						}}
						freeSolo
						renderOption={(props: any, option: any) => (
							<li style={option === "ALL" ? { fontWeight: "bold", color: "#034C7F", fontStyle: "italic" } : {}} {...props}>
								{option}
							</li>
						)}
						disablePortal={true}
						renderInput={(params: any) => <TextField {...params} placeholder="Location" />}
						ListboxProps={{
							style: {
								maxHeight: "150px",
							},
						}}
					/>
				</Grid>
				<Grid item md={6} xs={6} style={{ width: "100%" }}>
					<div style={{ width: "100%", overflow: "hidden" }}>
						<LocalOfferIcon style={{ float: "left" }} />
						<h4 style={{ float: "left", margin: 0, lineHeight: "27px", marginLeft: "10px" }}>Tag</h4>
					</div>
					<Autocomplete
						multiple
						options={props.tags}
						sx={{ width: "95%", margin: "10px 0px 20px 0px" }}
						getOptionLabel={(option: any) => {
							// Value selected with enter, right from the input
							if (typeof option === "string") {
								return option;
							}

							// Regular option
							return option.properties.name;
						}}
						onChange={(event: any, newValue: any) => {
							setTags(newValue);
						}}
						filterSelectedOptions
						filterOptions={(options: any, params: any) => {
							const filtered = filterSources(options, params);
							const hasAll =
								tags
									.map(function (e: any) {
										return e.id;
									})
									.indexOf("ALL") !== -1
									? true
									: false;
							return !hasAll ? filtered : [];
						}}
						freeSolo
						renderOption={(props: any, option: any) => (
							<li style={option.id === "ALL" ? { fontWeight: "bold", color: "#034C7F", fontStyle: "italic" } : {}} {...props}>
								{option.properties.name}
							</li>
						)}
						renderInput={(params: any) => <TextField {...params} placeholder="Tag" />}
						ListboxProps={{
							style: {
								maxHeight: "150px",
							},
						}}
					/>
				</Grid>
				<Grid item md={6} xs={6} style={{ width: "100%" }}>
					<div style={{ width: "100%", overflow: "hidden", marginBottom: "10px" }}>
						<TodayIcon style={{ float: "left" }} />
						<h4 style={{ float: "left", margin: 0, lineHeight: "27px", marginLeft: "10px" }}>Age Range</h4>
					</div>
					<TextField
						InputLabelProps={{
							style: { color: "rgba(0, 0, 0, 0.3)" },
						}}
						label="From"
						type="number"
						style={{ width: "47.5%", marginRight: "5%" }}
						InputProps={{ inputProps: { min: 0, max: 110 } }}
						value={minAge}
						onChange={(event: any) => setMinAge(event.target.value)}
					/>
					<TextField
						InputLabelProps={{
							style: { color: "rgba(0, 0, 0, 0.3)" },
						}}
						label="To"
						type="number"
						style={{ width: "47.5%" }}
						InputProps={{ inputProps: { min: 0, max: 110 } }}
						value={maxAge}
						onChange={(event: any) => setMaxAge(event.target.value)}
					/>
				</Grid>
				<div style={{ width: "100%", textAlign: "right" }}>
					<Button variant="contained" style={{ width: "140px", margin: "10px 0px" }} disabled={disableButton} onClick={requestData}>
						{!postingData ? <>Fetch Data</> : <CircularProgress size="26px" />}
					</Button>
				</div>
			</Grid>
		</div>
	);
}
