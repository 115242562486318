import { dataProvider } from "../../dataProvider";
import { Model } from "../Model";

export const getModel = async <T extends Model>(type: "Request" | "Tag" | "Source", relationKeys: string[], id: number, altQuery?: string) => {
	const Models = (await import("../Models")).default;

	let query = altQuery ? altQuery : type.toLowerCase();
	let result = await dataProvider(query + "/" + id, "GET");
	let json = await result.json();
	let model = new Models[type]();
	model.properties = json;

	for (const key in model.properties) {
		if (relationKeys.includes(key)) {
			(model.relations as any)[key] = (model.properties as any)[key];
		}
	}
	model.id = json.id;

	return model as T;
};
